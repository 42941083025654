<template>
    <div class="query mb20">
        <!-- <div class="query-item">
            <div class="address">行政区域</div>
            <div style="margin-left:30px">
                <hu-tag :list="land_area" v-model="params.land_area"/>
            </div>
        </div>
        <div class="query-item">
            <div class="address">土地类型</div>
            <div style="margin-left:30px">
                <hu-tag :list="land_type"  v-model="params.land_type"/>
            </div>
        </div>
        <div class="query-item">
            <div class="address">土地用途</div>
            <div style="margin-left:30px">
                <hu-tag :list="land_purpose" v-model="params.land_purpose"/>
            </div>
        </div>
        <div class="query-item">
            <div class="address">交易方式</div>
            <div style="margin-left:30px">
                <hu-tag :list="transaction_way" v-model="params.transaction_way" />
            </div>
        </div>
        <div class="query-item">
            <div class="address">交易状态</div>
            <div style="margin-left:30px">
                <hu-tag :list="transaction_status" v-model="params.transaction_status" />
            </div>
        </div>
        <div class="query-item">
            <div class="address">土地面积</div>
            <div style="margin-left:30px">
                <hu-tag :list="land_size" v-model="params.land_size" />
            </div>
        </div> -->
        <div class="query-item" v-for="(item,index) in land_group_selectors" :key="index">
            <div class="address">{{item.label}}</div>
            <div style="margin-left:30px">
                <hu-tag :list="item.options" v-model="useSearchStore[item.key]" :istype="istype" />
            </div>
        </div>
        <!-- <div class="query-item">
            <div class="address">挂网截止日</div>
            <div style="margin-left:30px">
                <hu-tag :list="land_dead_line" v-model="params.land_dead_line" />
            </div>
        </div> -->
    </div>
</template>

<script>
import huTag from '@/components/huTag'
import { useDict } from '@/hooks/dict';
import {useSearchStore} from '@/stores/search';

export default {
    name: 'GroupSelector',
    components: {
        huTag
    },
    created() {

    },
    data() {
        return {
            useSearchStore : useSearchStore(),
            params: {},
            istype: false,
        }
    },
    computed: {
        land_group_selectors(){
          let data=this.loadLandGroupSelectors();
          return data;
        }
    },
    mounted() {
       this.loadLandGroupSelectors()
      this.useSearchStore.$subscribe((mutation, state) => {
        console.log(state)
       if(state.transaction_way=="rent"){
         this.istype=true;

       }else {
         this.istype=false;
         if(state.demand_purpose=="water_land"  || state.demand_purpose=="transportation_land" )
         {
           state.demand_purpose='';
         }
       }
      })
    },
    methods: {
        getDict(dictName) {
          let data=useDict(dictName)[dictName].value;
          if(dictName==='transaction_status')
          {
            let arra=[];
            for (let i=0;i<data.length;i++)
            {
              if(data[i].label==='已成交' || data[i].label==='正在交易')
              {
                arra.push(data[i])
              }
            }
            data=arra
          }
          if(dictName==='transaction_way')
          {
            let arra=[];
            for (let i=0;i<data.length;i++)
            {
              if(data[i].label !== '出租')
              {
                arra.push(data[i])
              }
            }
          //  data=arra
          }

          return data;
        },
        loadLandGroupSelectors(){
            const {land_group_selector} = useDict('land_group_selector');
            return  land_group_selector.value.map(item=>({
                key:item.value,
                label:item.label,
                options: [{value:'',label:'不限'},...this.getDict(item.value)]
            }))

        }
    }
}
</script>

<style lang="scss" scoped>
.mt30 {
    margin-top: 30px;
}

.wd128 {
    width: 128px;
    display: inline-block;
    text-align-last: justify;
}

.img-wd {
    width: 100%;
    height: 320px;
}

.red {
    color: red;
}

.font16 {
    font-size: 18px;
}

.wd200 {
    width: 200px;
}

.query {
    // margin-top: 20px;
    background: #fff;
    padding: 15px;

    .query-item {
        display: flex;
        align-items: center;
        border-top: 1px solid #eee;
        line-height: 48px;

        .address {
            width: 130px;
            text-align: center;
            font-weight: 400;
            font-size: 14px;
        }

        div {
            span {
                padding: 5px 15px;
                background: #dedcdc;
                margin-right: 10px;
            }
        }
    }
}

::v-deep .info-container {
    .el-row {
        margin-bottom: 18px;

        &:last-child {
            margin-bottom: 0;
        }
    }
}


.map {
    height: 300px;
    width: 80%;
}
</style>
